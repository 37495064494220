import { css } from "@emotion/core"

// Global reset styles
const globals = css`
  html {
    font-size: 10px;

    * {
      box-sizing: border-box;
    }
  }

  html, body {
    font-family: 'Helvetica', sans-serif;
    margin: 0;
    padding: 0;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

export default globals