import { css } from '@emotion/core'
import * as fonts from '../fonts/index'

export default css`
  @font-face {
    font-family: 'Lato';
    src: url('${fonts.LatoRegular}?#iefix');
    src: url('${fonts.LatoRegular}?#iefix') format('truetype');
  }
  @font-face {
    font-family: 'Roboto';
    src: url('${fonts.RobotoRegular}?#iefix');
    src: url('${fonts.RobotoRegular}?#iefix') format('truetype');
  }
  @font-face {
    font-family: 'OpenSans';
    src: url('${fonts.OpenSansRegular}?#iefix');
    src: url('${fonts.OpenSansRegular}?#iefix') format('truetype');
  }
`