import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/media'

// Component

const Container = ({ isFullBleed=false, children }) => {
  return (
    <Capsule isFullBleed={isFullBleed}>
      {children}
    </Capsule>
  )
}

export default React.memo(Container)

// Styles

const Capsule = styled.div`
  margin: 0 auto;
  max-width: ${props => props.isFullBleed ?  '100%' : '96rem'}; // TODO Change it from global variable
  padding: 0 ${props => props.isFullBleed ? 0 : '4rem'};
  width: 100%;

  ${mq('sm')} {
    padding: 0 2rem;
  }
`
