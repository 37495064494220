import React from 'react'
import styled from '@emotion/styled'

import Container from './Container'

const Footer = (props) => {
  const {
    data: {
      footerAddress,
      footerCity,
      footerCopyright,
      footerEmail,
      footerTelephone,
      footerLogo: {
        url: logoUrl,
      }
    }
  } = props

  return (
    <FooterWrapper>
      <Container>
        <InnerWrapper>
          <ContentBlock>
            <FooterLogo src={logoUrl} alt="" />
          </ContentBlock>

          <ContentBlock>
            <TextInfo>{footerAddress}, {footerCity}</TextInfo>
          </ContentBlock>

          <ContentBlock>
            <TextInfo>{footerEmail}</TextInfo>
          </ContentBlock>

          <ContentBlock>
            <TextInfo>{footerTelephone}</TextInfo>
          </ContentBlock>

          <ContentBlock>
            <TextInfo>{footerCopyright}</TextInfo>
          </ContentBlock>
        </InnerWrapper>
      </Container>
    </FooterWrapper>
  )
}

// Styles -------

const FooterWrapper = styled.footer``

const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5rem 0;

  div {
    margin-bottom: 1rem;
    width: 100%;

    &:first-of-type {
      border-bottom: 0.1rem solid #CCCCCC;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const FooterLogo = styled.img`
  display: block;
  height: 7rem;
  width: 15rem;
`

const ContentBlock = styled.div`
  display: flex;
  justify-content: center;
`

const TextInfo = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
  margin: 0 1rem;
`

export default React.memo(Footer)