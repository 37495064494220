import styled from '@emotion/styled'
import { css } from '@emotion/core'
import mq from '../../styles/media'

export const Display1 = styled.h1`
  font-family: 'Lato';
  font-size: 6rem;
  letter-spacing: -0.02rem;
  line-height: 8rem;

  ${mq('sm')} {
    font-size: 3rem;
  }
`

export const Display2 = styled.h2`
  font-family: 'Lato';
  font-size: 3.2rem;
  letter-spacing: 0;
  line-height: 4rem;

  ${mq('sm')} {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
`

export const Headline = styled.span`
  font-family: 'Roboto';
  font-size: 2.8rem;
  letter-spacing: -0.02rem;
  line-height: 3rem;

  ${mq('sm')} {
    font-size: 2.6rem;
    line-height: 3rem;
  }
`

export const BodyTypo = css`
  font-family: 'OpenSans';
  font-size: 1.8rem;
  letter-spacing: -0.02rem;
  line-height: 3.2rem;

  ${mq('sm')} {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`