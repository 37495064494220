const bp = {
  sm: 375,
  md: 768,
  lg: 1025,
  xl: 1440,
}

const mq = n => {
  const bpArray = Object.keys(bp).map(key => [key, bp[key]])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export default mq