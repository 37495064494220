import { css } from '@emotion/core'

import globals from './globals'
import fonts from './fonts'

const globalStyles = css`
  ${globals}
  ${fonts}
` 

export default globalStyles