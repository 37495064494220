import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from '@emotion/styled'

const Gallery = (props) => {
  const {
    data: {
      galleryImage: imageList,
    },
  } = props

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const images = imageList.map((el, i) =>
    <div key={`gallery-image-${i}`}>
      <img src={el.url} alt="" />
    </div>
  )

  return (
    <GalleryWrapper>
      <Slider {...settings}>
        {images}
      </Slider>
    </GalleryWrapper>
  )
}

// Styles ------

const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .slick-prev {
    left: 2rem;
  }

  .slick-next {
    right: 2rem;
  }

  .slick-dots {
    bottom: auto;
    margin-top: 1rem;
    position: relative;
  }
`

export default React.memo(Gallery)