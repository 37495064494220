import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import mq from '../styles/media'

import { Display1 } from './atoms/Typography'

// Component

const Hero = (props) => {
  const {
    data
  } = props

  return (
    <HeroWrapper>
      <BackgroundImage imageUrl={data.image.url} />
      <Display1 css={titleStyles}>{data.title}</Display1>
    </HeroWrapper>
  )
}

export default React.memo(Hero)

// Styles

const HeroWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 50rem;
  justify-content: center;
  margin-top: 5.5rem;
  position: relative;
  width: 100%;

  ${mq('sm')} {
    height: 30rem;
  }
`

const titleStyles = css`
  color: white;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${mq('sm')} {
    padding: 0 2rem;
  }
`

const BackgroundImage = styled.div`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`
