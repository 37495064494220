import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Container from './Container'

import { Headline, BodyTypo } from './atoms/Typography'

const Information = (props) => {
  const { data } = props
  const { description, ctaLabel, ctaUrl, title } = data

  return (
    <Container>
      <InformationWrapper>
        <Headline css={titleStyles}>{title}</Headline>
        <Description>{description}</Description>
        <Button href={ctaUrl}>{ctaLabel}</Button>
      </InformationWrapper>
    </Container>
  )
}

export default React.memo(Information)

// Styles

const InformationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 6rem 0;
  width: 100%;
`

const titleStyles = css`
  margin-bottom: 2.5rem;
`

const Description = styled.p`
  ${BodyTypo}
  margin: 0 0 2.5rem;
`

const Button = styled.a`
  background-color: #223b72; /* TODO Change color to global var */
  color: white;
  display: inline-block;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  text-decoration: none;
`