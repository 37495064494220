import React from 'react'
import styled from '@emotion/styled'

import Container from './Container'

const FullBleedImage = (props) => {
  const { data: { fullBleedImage: { url } } } = props

  return (
    <Container isFullBleed>
      <ImageElement src={url} alt="fullbleed"/>
    </Container>
  )
}

export default FullBleedImage

const ImageElement = styled.img`
  display: block;
  height: auto;
  width: 100%;
`
