import React from 'react'
import _ from 'lodash'

import Hero from './Hero'
import Information from './Information'
import InformationGallery from './InformationGallery'
import Gallery from './Gallery'
import FullBleedImage from './FullBleedImage'

const ModuleFactory = (props) => {
  const { modulesData } = props
  const modulesArray = []

  modulesData.forEach((i) => {
    const id = _.get(i, 'id', '')
    const moduleName = id.split('-')[0]

    switch (moduleName) {
      case 'DatoCmsHero':
        modulesArray.push(<Hero key={i.id} data={i} />)
        break
      case 'DatoCmsInformation':
        modulesArray.push(<Information key={i.id} data={i} />)
        break
      case 'DatoCmsGallery':
        modulesArray.push(<Gallery key={i.id} data={i} />)
        break
      case 'DatoCmsFullbleedimage':
        modulesArray.push(<FullBleedImage key={i.id} data={i} />)
        break
      case 'DatoCmsInformationGallery':
        modulesArray.push(<InformationGallery key={i.id} data={i} />)
        break
      default:
        break
    }
  })

  return (
    <>
      {modulesArray}
    </>
  )
}

export default ModuleFactory