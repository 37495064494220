import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import mq from '../styles/media'
import Container from './Container'

import { Headline, BodyTypo } from './atoms/Typography'

const InformationGallery = (props) => {
  const { data } = props
  const { description, ctaLabel, ctaUrl, title, gallery } = data

  const imageElements = gallery.map((el, i) => {
    return (
      <GalleryImageWrapper key={i}>
        <GalleryImageElement src={el.url} />
      </GalleryImageWrapper>
    )
  })

  return (
    <Container>
      <InformationGalleryWrapper>
        <Headline css={titleStyles}>{title}</Headline>
        <Description>{description}</Description>
        <GalleryWrapper>
          {imageElements}
        </GalleryWrapper>
        <Button href={ctaUrl}>{ctaLabel}</Button>
      </InformationGalleryWrapper>
    </Container>
  )
}

export default React.memo(InformationGallery)

// Styles ------

const InformationGalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 6rem 0;
  width: 100%;
`

const titleStyles = css`
  margin-bottom: 2.5rem;
`

const Description = styled.p`
  ${BodyTypo}
  margin: 0 0 2.5rem;
`

const Button = styled.a`
  background-color: #223b72; /* TODO Change color to global var */
  color: white;
  display: inline-block;
  font-size: 1.6rem;
  margin-bottom: 2.5rem;
  padding: 1rem 3rem;
  text-decoration: none;
`

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
  padding: 0 10%;
  width: 100%;

  ${mq('sm')} {
    padding: 0;
  }
`

const GalleryImageWrapper = styled.div`
  height: auto;
  padding: 1rem;
  width: 50%;
`

const GalleryImageElement = styled.img`
  display: block;
  height: 20rem;
  width: 100%;

  ${mq('sm')} {
    height: auto;
    width: 100%;
  }
`