import React from 'react'
import { Global } from "@emotion/core"

import globalStyles from '../styles'

const Layout = ({ children }) => (
  <>
    <Global styles={globalStyles} />
    {children}
  </>
)

export default React.memo(Layout)