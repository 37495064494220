import React from 'react'
import styled from '@emotion/styled'

import Container from './Container'

const Nav = (props) => {
  const {
    data: {
      logo: {
        url: logoUrl
      },
      phone: phoneNum,
    }
  } = props

  return (
    <NavWrapper>
      <Container>
        <NavInnerWrapper>
          <a href="/">
            <Logo src={logoUrl} alt="" />
          </a>

          <Phone href={`tel:+57${phoneNum}`}>{phoneNum}</Phone>
        </NavInnerWrapper>
      </Container>
    </NavWrapper>
  )
}

// Styles ------

const NavWrapper = styled.nav`
  background-color: #FFFFFF;
  left: 0;
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`

const NavInnerWrapper = styled.div` 
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`

const Logo = styled.img`
  display: block;
  height: auto;
  width: 10rem;
`

const Phone = styled.a`
  background-color: #223B72;
  color: #FFFFFF;
  display: block;
  font-family: 'Lato';
  font-size: 1.2rem;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: all .5s ease;

  &:hover {
    background-color: #3C5A9A;
  }
`

export default React.memo(Nav)
