// import React from "react";

// import SEO from "../components/seo";
// import "../components/landing.css";

// const IndexPage = () => (
//   <React.Fragment>
//     <SEO title="Home" keywords={[`logistic`, `bogota`, `logística`, `colombia`]} />

//     <h1>J&C Supply Logistics SAS</h1>
//     <h2>¡Estamos en construcción!</h2>

//     <span className="logo" role="img" aria-label="emoji box">📦</span>

//     <div className="contact">
//       <span className="subhead">Contáctanos</span>
//       <p>Cesar Rodriguez Fuentes - CEO</p>
//       <p>crodriguez@jycsupply.com</p>
//       <p>+57 318-280-0948</p>
//     </div>
//   </React.Fragment>
// );

// export default IndexPage;

import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import Nav from '../components/Nav'
import Layout from '../components/Layout'
import ModuleFactory from '../components/ModuleFactory'
import Footer from '../components/Footer'

const IndexPage = (props) => {
  const { data } = props
  const navData = _.get(data, 'allDatoCmsHomepage.nodes[0].nav')
  const footerData = _.get(data, 'allDatoCmsHomepage.nodes[0].footer')
  const modulesData = _.get(data, 'allDatoCmsHomepage.nodes[0].content', [])

  return (
    <>
      <Nav data={navData} />
      <Layout>
        <ModuleFactory modulesData={modulesData} />
      </Layout>
      <Footer data={footerData} />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    allDatoCmsHomepage {
      nodes {
        introText
        nav {
          logo {
            url
          }
          phone
        }
        content {
          ... on DatoCmsHero {
            id
            title
            image {
              url
            }
          }
          ... on DatoCmsInformation {
            id
            description
            ctaLabel
            ctaUrl
            title
          }
          ... on DatoCmsFullbleedimage {
            id
            fullBleedImage {
              url
            }
          }
          ... on DatoCmsInformationGallery {
            id
            description
            ctaLabel
            ctaUrl
            title
            gallery {
              url
            }
          }
          ... on DatoCmsGallery {
            id
            galleryImage {
              url
            }
          }
        }
        footer {
          id
          footerAddress
          footerCity
          footerCopyright
          footerEmail
          footerTelephone
          footerTitle
          footerLogo {
            url
          }
        }
      }
    }
  }
`
